import React, { Suspense } from 'react';
import "../css/index.css";
import IndexHeader from "../components/index_header";
import IndexContent from "../components/index_content";
import IndexFooter from "../components/index_footer";
import {
    BrowserRouter as Router,
    Route,
    NavLink,
    Switch,
    HashRouter
} from 'react-router-dom';
const LogisticsProvider = React.lazy(()=>import("../pages/logistics_provider"))

export default class Index extends React.Component {
    state = {
        token:localStorage.getItem("token"),
        email:localStorage.getItem("email")
    }
    render() {
        return <section>
            <IndexHeader />
            <div>
            <HashRouter>
                <Switch>
                    <Suspense fallback={<div className="y-flex" style={{width:'100vw',height:"80vh"}}>loading...</div>}>
                        <Route exact path="/" component={IndexContent} />
                        <Route path="/index/home" component={IndexContent} />
                        <Route path="/index/logistics_register" component={LogisticsProvider} />
                    </Suspense>
                </Switch>
            </HashRouter>
            </div>
            <IndexFooter />
        </section>
    }
}