/**
 * 用户注册模块
 */
import React, { Component } from 'react';
import {
      Input, Button, Checkbox, message
} from 'antd';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import {InfoCircleOutlined,CheckOutlined } from '@ant-design/icons';
import {config  } from "../conifg/config"
const Err = (<InfoCircleOutlined /> );
const Ok = (<CheckOutlined />);
interface RegisterProps {
    registerEnd:()=>any;
}
class Register extends Component<RegisterProps> {
    constructor(props:RegisterProps) {
        super(props);
        this.inputChange = this.inputChange.bind(this);
        this.reg = this.reg.bind(this);
    }
    inputChange(e: any) {
        let Input = e.target;
        console.log(Input.name)
        switch (Input.name) {
            case 'userName': {
                if (Input.value && Input.value.length < 15) {
                    this.setState({
                        userName:Input.value,
                        userNameSuffix:Ok
                    })
                }else{
                    this.setState({
                        userNameSuffix:Err
                    })
                }
                break;
            };
            case 'userCount': {
                let reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); 
                if (Input.value && Input.value.length < 32 && Input.value.length > 8 && reg.test(Input.value)) {
                    this.setState({
                        userCount:Input.value,
                        userCountSuffix:Ok
                    });
                }else{
                    this.setState({
                        userCountSuffix:Err
                    });
                }
                break;
            };
            case 'userPassword': {
                console.log('userPassword')
                if (Input.value && Input.value.length > 7 && Input.value.length < 16) {
                    this.setState({
                        userPassword:Input.value,
                        userPasswordSuffix:Ok
                    });
                }else{
                    this.setState({
                        userUserPasswordSuffix:Err
                    });
                }
                if (Input.value == this.state.userPassword2) {
                    this.setState({
                        userPassword2Suffix:Ok
                    });
                }else{
                    console.log('xxx')
                    this.setState({
                        userUserPassword2Suffix:Err
                    });
                }
                break;
            };
            case 'userPassword2': {
                if (Input.value == this.state.userPassword) {
                    this.setState({
                        userPassword2:Input.value,
                        userPassword2Suffix:Ok
                    });
                }else{
                    this.setState({
                        userUserPassword2Suffix:Err
                    });
                }
                break;
            };
            case 'agreenTOS':{
                console.log(Input.checked)
                this.setState({
                    termsOfService:Input.checked
                })
                break;
            }
            default:break;
        }
    }
    state = {
        userNameSuffix: Err,
        userCountSuffix: Err,
        userPasswordSuffix: Err,
        userPassword2Suffix: Err,
        userName: '',
        userCount: '',
        userPassword: '',
        userPassword2: '',
        termsOfService: false
    }
    /**
     * 注册
     */
    reg() {
        if(this.state.userNameSuffix == Err){
            message.error('请输入名称，并且小于15个字符');
            return;
        }
        if(this.state.userCountSuffix == Err){
            message.error('请输入合法的账号！');
            return;
        }
        if(this.state.userPasswordSuffix == Err){
            message.error('密码长度至少7位！');
            return;
        }
        if(this.state.userPassword != this.state.userPassword2){
            message.error('两次输入密码不一致！');
            return;
        }
        if(!this.state.termsOfService){
            message.error('您还未同意服务条款！');
            return;
        }
        axios.post(`${config.serverUrl}/api/auth/register`,{
            name:this.state.userName,
            email:this.state.userCount,
            password:this.state.userPassword
        }).then((res)=>{
            if(res.status != 200){
                message.error(res.statusText);
            }else{
                if(res.data.code != 0){
                    message.error(res.data.message);
                }else{
                    message.success(res.data.message);
                    this.setState({
                        userNameSuffix: Err,
                        userCountSuffix: Err,
                        userPasswordSuffix: Err,
                        userPassword2Suffix: Err,
                        userName: '',
                        userCount: '',
                        userPassword: '',
                        userPassword2: '',
                        termsOfService: false
                    })
                    this.props.registerEnd();
                }
            }
        }).catch((err)=>{

        })
    }
    render() {
        return (
            <div className="section-register">
                <div style={{ marginBottom: 16 }}>
                    <Input addonBefore="名称" suffix={this.state.userNameSuffix} type="text" defaultValue="" placeholder="请输入您的名称" name="userName" onChange={this.inputChange} />
                </div>
                <div style={{ marginBottom: 16 }}>
                    <Input addonBefore="账号" suffix={this.state.userCountSuffix} type="text" defaultValue="" placeholder="请输入您的邮箱" name="userCount" onChange={this.inputChange} />
                </div>
                <div style={{ marginBottom: 16 }}>
                    <Input addonBefore="密码" suffix={this.state.userPasswordSuffix} type="password" defaultValue="" placeholder="请输入您的密码" name="userPassword" onChange={this.inputChange} />
                </div>
                <div style={{ marginBottom: 16 }}>
                    <Input addonBefore="确认密码" suffix={this.state.userPassword2Suffix} type="password" defaultValue="" placeholder="请再次输入密码" name="userPassword2" onChange={this.inputChange} />
                </div>
                <div>
                    <Checkbox onChange={this.inputChange} name="agreenTOS">我已阅读并且同意 <NavLink to="/termsofservice">《服务条款》</NavLink> </Checkbox>
                </div>
                <div className="y-fe">
                    <Button type="primary" onClick={this.reg}>注册</Button>
                </div>
            </div>
        )
    }
}
export default Register;