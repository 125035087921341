import React from 'react';
export default class IndexHeader extends React.Component{
    state = {
        token:localStorage.getItem("token"),
        email:localStorage.getItem("email")
    }
    render(){
        // <a style={{lineHeight:"90px"}} href="#/main">管理后台</a>
        return <div id="head">
            <div className="head-bg">
                <div className="head-container">
                    <img src="./assets/img/logo.jpg" className="head-logo" alt="JVcustom" />
                    <ul className="head-nav">
                        <li><a className="nav" href="#/index">首页</a></li>
                        <li><a className="nav" href="#">招商合作</a></li>
                        {this.state.token ? <li><a className="nav" href="#/index/logistics_register">App申请</a></li> : ""}     
                        <li><a className="nav" href="#">平台介绍</a></li>
                        <li><a className="nav" href="#">帮助中心</a></li>
                    </ul>
                    {this.state.token ? <span><a style={{lineHeight:"90px"}}> {this.state.email} </a>&nbsp;&nbsp;</span> : <span><a className="signup" href="#/register">注册</a>
                    <a className="signin" href="#/login">立即登录</a></span>}

                </div>
            </div>
    </div>
    }
}