/**
 * 用户登录模块
 */
import {
 Input, Button, message,Modal
} from 'antd';
import React from 'react';
import axios from 'axios';
import {config  } from "../conifg/config"
import { createHashHistory } from 'history';
// import { configConsumerProps } from 'antd/lib/config-provider';
class Login extends React.Component {
   constructor(props: any) {
       super(props);
       this.loginChange = this.loginChange.bind(this);
    //    this.getCodeImage = this.getCodeImage.bind(this);
    //    this.alipayLogin = this.alipayLogin.bind(this);
       this.login = this.login.bind(this);
    //    this.openBindModal = this.openBindModal.bind(this);
    //    this.bindInputChange = this.bindInputChange.bind(this);
    //    this.thirdBind = this.thirdBind.bind(this);
   }
   state = {
       email: '',//邮箱
       password: '',//密码
       isReq:false,
       codeImageUrl:"",
       captcha:"",
       key:"",
       isOk:false,
       url:"",


    //    uuid:"",//随机生成的uuid
    //    bindModalVisible:false,//绑定弹窗、
    //    bindName:"",
    //    bindNameSuffix:Err,
    //    bindEmail:"",
    //    bindEmailSuffix:Err,
    //    bindPassword:"",
    //    bindPasswordSuffix:Err,
    //    bindPassword2:"",
    //    bindPassword2Suffix:Err,
    //    bindReq:false,
   }
   /**
    * 登录
    */
   login(data?:any) {
       this.setState({
           isReq:true
       })
       let submitData = {};
       if(!data){
           submitData = {
               email:this.state.email,
               password:this.state.password,
               captcha:this.state.captcha,
               key:this.state.key
           }
       }else{
           submitData = data;
       }
       axios.post(`${config.serverUrl}/api/auth/login`,submitData).then((res)=>{
           if(res.status !== 200){
               message.error(res.statusText);
           }else{
               // eslint-disable-next-line
               if(res.data.code != 0){
                   this.setState({
                       isReq:false
                   })
                   message.error(res.data.message)
               }else{
                   //读取返回的token
                   let token = res.data.access_token;
                   //设置显示弹窗
                   localStorage.setItem("token",token)
                   localStorage.setItem("email",res.data.email);
                   const history = createHashHistory();
                   history.push("/index");
               }
           }
           this.setState({
               isReq:false
           })
       }).catch((err)=>{
           console.log(err)
           this.setState({
               isReq:false
           })
       })
   }
   componentDidMount(){
    //    this.getCodeImage();
   }
   /**
    * 账号输入
    * @param e 
    */
   loginChange(e: any) {
       let input = e.target;
       if (input.name === 'userName') {
           this.setState({
               email: input.value
           })
       } else if (input.name === 'userPassword') {
           this.setState({
               password: input.value
           })
       }else if(input.name === "captcha"){
           this.setState({
               captcha:input.value
           })
       }
   }
//    /**
//     * 获取验证码图片
//     */
//    getCodeImage(){
//        // this.setState({isOk:false})
//        let codeUrl = `${rootUrl}/get_captcha/math?`+Math.random()
//        axios({
//            method:"GET",
//            url:codeUrl,
//        }).then((res:any)=>{
//            console.log(res);
//            // eslint-disable-next-line
//            if(res.status != 200){
//                this.setState({isOk:false})
//                message.error('获取数据失败！')
//            }else {
//                let data = res.data;
//                this.setState({
//                    codeImageUrl:data.url.img,
//                    key:data.url.key,
//                    isOk:true
//                })
//            }
//        }).catch(err => {
//            console.log(err)
//            for(let item in err){
//                console.log(err[item])
//            }
//            this.setState({isOk:false})
//            try {
//                if (err.response.status === 401) {
//                    message.error('登录超时,请从新登录！')
//                } else if (err.response.status === 500) {
//                    message.error('服务器错误！')
//                } else if (err.response.status === 403) {
//                    message.error('服务器拒绝了您的请求！')
//                } else if (err.response.status === 404) {
//                    message.error('该数据不存在！')
//                }

//            } catch (error) {
//                console.log(error)
//            }
//        })
//    }
   render() {
       return (
           <div className="section-login">
               <div className="y-login-input">
                   <Input addonBefore="账号" type="text" defaultValue="" name="userName" onChange={this.loginChange} />
               </div>
               <div className="y-login-input">
                   <Input addonBefore="密码" type="password" defaultValue="" name="userPassword" onChange={this.loginChange} />
               </div>
               {/* <div className="y-login-input">
                   <Input addonBefore="验证码" name="captcha" defaultValue="" style={{width:200}} onChange={this.loginChange} />
                   {this.state.isOk ? <img width="108" height="33" onClick={this.getCodeImage} src={this.state.codeImageUrl} style={{margin:"0 20px",border:"1px solid #DEDEDE"}} alt=""/> : <span style={{margin:"0 20px"}}><button onClick={this.getCodeImage}>点击刷新验证码</button></span> }        
               </div> */}
               {/* <div className="y-login-input">
                   <img width="100" height="100" src={codeUrl} alt=""/>
               </div> */}
               <div className="y-fe y-login-input">
                   <Button style={{width:500}} type="primary" onClick={()=>{this.login()}} loading={this.state.isReq}>登录</Button>
               </div>
               {/* <div>
                   <span>其他登录:&nbsp;&nbsp;</span>
                   <span>
                       <span onClick={this.alipayLogin}>
                           <img width={32} height={32} src={alipayIcon} alt="支付宝登录"/>
                       </span>
                   </span>
               </div> */}
               {/* 绑定弹窗
               <Modal
                   visible={this.state.bindModalVisible}
                   title="请绑定您的邮箱"
                   onOk={this.thirdBind}
                   okText="绑定"
                   cancelButtonProps={{
                       className:"y-hidden"
                   }}
                   onCancel={()=>{message.warning("请绑定您的邮箱！")}}
                   confirmLoading={this.state.bindReq}
               >
                   <div className="y-login-input">
                       <Input addonBefore="名称" suffix={this.state.bindNameSuffix} type="text" defaultValue="" name="bindUserName" onChange={this.bindInputChange} />
                   </div>
                   <div className="y-login-input">
                       <Input addonBefore="邮箱" suffix={this.state.bindEmailSuffix} type="text" defaultValue="" name="bindUserEmail" onChange={this.bindInputChange} />
                   </div>
                   <div className="y-login-input">
                       <Input addonBefore="密码" suffix={this.state.bindPasswordSuffix} type="password" defaultValue="" name="bindUserPassword" onChange={this.bindInputChange} />
                   </div>
                   <div className="y-login-input">
                       <Input addonBefore="确认密码" suffix={this.state.bindPassword2Suffix} type="password" defaultValue="" name="bindUserPassword2" onChange={this.bindInputChange} />
                   </div>
               </Modal> */}
           </div>
       );
   }
}
export default Login