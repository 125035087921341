/**
 * 主要功能页面
 * 该页面登录才能访问
 */
import React,{Component} from 'react';
import BaseRouter from '../routers/router';
import { message} from 'antd';
import { Redirect}  from 'react-router-dom';
import { getToken } from "../utils";
import Header from '../components/header';
class Main extends Component{
    constructor(props:any){
        super(props);
        
    }
    componentDidMount(){
    }
    state = {
        token:getToken(),
        count:localStorage.getItem("email") || ""
    }
    render(){
        if(!this.state.token){
            console.log('xxx')
            message.error('登录超时或者未登录！');
            return <Redirect to="/index" />
        }
        return(
            <div>
                <Header token={this.state.token} count={this.state.count}></Header>
                <main>
                    <BaseRouter/>
                </main>
            </div>
        )
    }
}
export default Main;