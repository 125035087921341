import React from 'react';
export default class IndexContent extends React.Component {
    render() {
        return <section>
            <div id="banner"></div>
            {/*  */}
            <div id="info">
                <div className="info-a">
                    <img src="../assets/img/icon_供应商.jpg" height="50px;" style={{ marginTop: 32, marginBottom: 8 }} /> <br />
                    <b>152</b>&nbsp;家供应商
                    <div className="line"></div>
                </div>
                <div className="info-b">
                    <img src="../assets/img/icon_设计师.jpg" height="50px;" style={{ marginTop: 32, marginBottom: 8 }} /><br />
                    <b>454245</b>&nbsp;位设计师
                    <div className="line"></div>
                </div>
                <div className="info-b">
                    <img src="../assets/img/icon_平台.jpg" height="50px;" style={{ marginTop: 32, marginBottom: 8 }} /><br />
                    <b>45</b>&nbsp;个销售平台
                    <div className="line"></div>
                </div>
                <div className="info-b">
                    <img src="../assets/img/icon_销售国家.jpg" height="50px;" style={{ marginTop: 32, marginBottom: 8 }} /><br />
                    <b>156</b>&nbsp;个销售国家
                </div>
            </div>
            {/*  */}
            <div id="category">
                <img src="../assets/img/标题圆角.png" />
                <p className="category-title">优质产品 为您精选</p>
                <h1>招商品类</h1>
                <div className="category-list">
                    <div className="category-list-a"><img src="../assets/img/产品图.jpg" /></div>
                    <div className="category-list-b"><img src="../assets/img/产品图.jpg" /></div>
                    <div className="category-list-b"><img src="../assets/img/产品图.jpg" /></div>
                    <div className="category-list-b"><img src="../assets/img/产品图.jpg" /></div>
                    <div className="category-list-a"><img src="../assets/img/产品图.jpg" /></div>
                    <div className="category-list-b"><img src="../assets/img/产品图.jpg" /></div>
                    <div className="category-list-b"><img src="../assets/img/产品图.jpg" /></div>
                    <div className="category-list-b"><img src="../assets/img/产品图.jpg" /></div>
                </div>
            </div>
            {/*  */}
            <div id="advantage">
                <img src="../assets/img/标题圆角.png" />
                <p className="advantage-title">优势突出 轻松赚钱</p>
                <h1>急速入驻</h1>
                <div className="advantage-list">
                    <div className="advantage-list-a">
                        <div className="advantage-list-icon"><img src="../assets/img/A.png" /></div>
                        <div className="advantage-list-icon-active">
                            <img width="48" height="48" src="../assets/img/A_hover.png" />
                        </div>
                        <div className="advantage-list-text">
                            <p><b>国内最大规模</b></p>
                            <p className="text">国内最大规模跨境互联网订制平台</p>
                        </div>
                    </div>
                    <div className="advantage-list-b">
                        <div className="advantage-list-icon">
                            <img src="../assets/img/B.png" />
                        </div>
                        <div className="advantage-list-icon-active">
                            <img src="../assets/img/B_hover.png" />
                        </div>
                        <div className="advantage-list-text">
                            <p><b>产品种类繁多</b></p>
                            <p className="text">家居用品、3C产品、鞋类、箱包等 可供客户定制的产品超1000种</p>
                        </div>
                    </div>
                </div>
                <div className="advantage-list-b">
                    <div className="advantage-list-icon"><img src="../assets/img/C.png" /></div>
                    <div className="advantage-list-icon-active">
                        <img src="./assets/img/C_hover.png" />
                    </div>
                    <div className="advantage-list-text">
                        <p><b>最强团队阵容</b></p>
                        <p className="text">已邀请国外5000多位知名设计师进驻平台， 定制成数千万优秀设计产品</p>
                    </div>
                </div>
                <div className="advantage-list-b">
                    <div className="advantage-list-icon">
                        <img src="../assets/img/D.png" />
                    </div>
                    <div className="advantage-list-icon-active">
                        <img src="../assets/img/D_hover.png" />
                    </div>
                    <div className="advantage-list-text">
                        <p><b>优秀战略合作模式</b></p>
                        <p className="text">邀请线下更多工厂入驻平台， 采取共赢互利商业合作模式</p>
                    </div>
                </div>
            </div>
            <div id="announcement">
                <div className="announcement-container">
                    <img src="../assets/img/标题圆角.png/" style={{ marginTop: 150 }} />
                    <p className="announcement-title">信息透明 拒绝滞后</p>
                    <h1 style={{ color: "white" }}>平台公告</h1>
                    <div className="announcement-a">
                        <b>2019年交易技术服务费免收公告{">"}</b>
                        <p>尊敬的商家朋友： 您好，衷心感谢您在过去的一年给予的关注与支持！您好，衷心感谢您在过去的一年给予的关注与支持！</p>
                    </div>
                    <div className="announcement-a" style={{ marginRight: 30 }}>
                        <b>商家投诉渠道开通{">"}</b>
                        <p>商家投诉邮箱开通 为了提高商家对平台服务的满意度，促进商家与平台之间的有效沟通，设立商家投诉邮箱</p>
                    </div>
                </div>
            </div>
            <div id="faqs">
                <div className="faqs-container">
                    <img src="../assets/img/标题圆角.png" />
                    <p className="faqs-title">信息透明 拒绝滞后</p>
                    <h1>常见问题</h1>
                    <div className="faqs-list">
                        <div className="faqs-list-a">
                            <div className="faqs-text">
                                <b>2019年交易技术服</b>
                                <p>尊敬的商家朋友： 您好，衷心感谢您在过去的一年给予的关注与支持！在大家的携手努力下，2016年获尊敬的商家朋友：
                                    您好，衷心感谢您在过去的一年给予的关注与支持！在大家的携手努力下，2016年获</p>
                            </div>
                        </div>
                        <div className="faqs-list-b">
                            <div className="faqs-text">
                                <b>2017年交易技术服</b>
                                <p>尊敬的商家朋友： 您好，衷心感谢您在过去的一年给予的关注与支持！在大家的携手努力下，2016年获尊敬的商家朋友： 力下，201</p>
                            </div>
                        </div>
                        <div className="faqs-list-b">
                            <div className="faqs-text">
                                <b>2017年交易技术服</b>
                                <p>尊敬的商家朋友： 您好，衷心感谢您在过去的一年给予的关注与支持！在大家的携手努力下，2016年获尊敬的商家朋友：
                                    您好，衷心感谢您在过去的一年给予的关注与支持！在大家的携手努力下，2016年获</p>
                            </div>
                        </div>
                        <div className="faqs-list-b">
                            <div className="faqs-text">
                                <b>2017年交易技术服</b>
                                <p>尊敬的商家朋友： 您好，衷心感谢您在过去的一年给予的关注与支持！在大家的携手努力下，2016年获尊敬的商家朋友：
                                    您好，衷心感谢您在过去的一年给予的关注与支持！在大家的携手努力下，2016年获</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }
}