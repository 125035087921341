import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  Route,
  Switch,
  HashRouter
} from 'react-router-dom';
import Login from "./pages/login";
import Register from './pages/register';
import Main from './pages/main';
import Index from "./pages/index"
import  LogisticsProvider  from './pages/logistics_provider'
function App() {
  return (
    <div className="App">
      <HashRouter>
        <Switch>
          <Route exact path="/" component={Index} />
          <Route path="/login" component={Login} />
          <Route path="/logistics_register" component={LogisticsProvider} />
          <Route path="/main" component={Main} />
          <Route path="/register" component={Register} />
          <Route path="/index" component={Index} />
        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
