import React from 'react';
export default class IndexFooter extends React.Component {
    render() {
        return <div id="footer">
            <div className="footer-container">
                <img width="180" height="40" src="../assets/img/logo单色.png" className="footer-logo" />
                <div className="footer-text">
                    <p className="footer-text-bold"><b>关于我们</b></p>
                    <a>平台介绍</a><br />
                    <a>发展历程</a><br />
                    <a>平台荣誉</a><br />
                </div>
                <div className="footer-text">
                    <p className="footer-text-bold"><b>平台服务</b></p>
                    <a>新手指南</a><br />
                    <a>招商PPT下载</a><br />
                    <a>帮助中心</a><br />
                    <a>论坛</a><br />
                </div>
                <div className="footer-text">
                    <p className="footer-text-bold"><b>联系我们</b></p>
                    <a>400-800-8888</a><br />
                    <a>service@yijiele.com</a><br />
                </div>
                <div className="footer-text">
                    <p className="footer-text-bold"><b>关注微信</b></p>
                    <p><img src="../assets/img/wechat.jpg" /></p>
                </div>
            </div>
        </div>
    }
}