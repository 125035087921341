/**
 * 主页的的导航条
 */
import React, { Component } from 'react';
import { Menu,Row,Col,Badge} from 'antd';
import {NavLink,Redirect} from 'react-router-dom';
import '../css/header.css';
import { ShoppingCartOutlined } from '@ant-design/icons';
// import { loginOut } from '../util';
const SubMenu = Menu.SubMenu;
const mapStateToProps = (state:any) => {
    return {
        messageCount:state.login.messageCount,
        cartProductCount:state.cart.productCount,
        cartList:state.cart.cartList
    }
}
export interface HeaderProps{
    count?:string;
    token?:string;
    messageCount?:number;//未读消息数量
    cartProductCount?:number;
}
const headerStyle = {
    // background: "#00b1fd -webkit-gradient(linear,right top,left top,from(#0072f3),to(#00b1fd))",
    // background: "#00b1fd -webkit-linear-gradient(right,#0072f3 0,#00b1fd 100%)",
    // background: "#00b1fd -o-linear-gradient(right,#0072f3 0,#00b1fd 100%)",
    background: "#00b1fd linear-gradient(-90deg,#0072f3 0,#00b1fd 100%)",
    borderTopColor: "#006af1",
    // border-bottom-color: #e9f2fb;
    color:"white",
    // paddingTop:16    
    // minHeight:80
}
class Header extends Component<HeaderProps> {
    constructor(props: any) {
        super(props)
        this.loginOut = this.loginOut.bind(this);
    }
    state = {
        current: 'image',
        loginOut:false,
        email:localStorage.getItem("email")
    }
    handleClick = (e: any) => {
        this.setState({
            current: e.key,
        });
    }
    loginOut(e:any){
        // loginOut().then(res=>{
        //     console.log(res)
        //     clearToken();
        //     this.setState({
        //         loginOut:true
        //     })
        // }).catch(err=>{
        //     console.log(err);
        //     // localStorage.clear()
        //     clearToken();
        //     this.setState({
        //         loginOut:true
        //     })
        // })
    }
    render() {
        // if(this.state.loginOut){
        //     return <Redirect to="/" />
        // }
        return (
            <header>
                <div>
                    <Row>
                        <Col span={24}>
                            <Menu
                                onClick={this.handleClick}
                                selectedKeys={[this.state.current]}
                                mode="horizontal"
                                style={headerStyle}
                                // className="y-header-style"
                            >
                                <Menu.Item key="gonggao" >
                                    <NavLink to="/main/home">
                                         {/* <Icon type="profile" /> */}
                                         首页
                                    </NavLink>  
                                </Menu.Item>
                                <Menu.Item key="baregoods">
                                    <NavLink to="/main/baregoods">
                                        {/* <Icon type="picture" /> */}
                                        选品
                                    </NavLink>                                  
                                </Menu.Item>
                                <Menu.Item key="mail">
                                    <NavLink to="/main/image">
                                        {/* <Icon type="picture" /> */}
                                        图片库
                                    </NavLink>                                  
                                </Menu.Item>
                                <Menu.Item key="warehouse">
                                    <NavLink to="/main/warehouse/stock_list">
                                    {/* <FontAwesomeIcon icon="warehouse"/> */}
                                    &nbsp;&nbsp;仓库
                                    </NavLink>                                  
                                </Menu.Item>
                                <Menu.Item key="order">
                                    <NavLink to="/main/order/orderlist">
                                        {/* <Icon type="file-done" /> */}
                                        &nbsp;&nbsp;订单管理
                                    </NavLink>                                  
                                </Menu.Item>
                                {/* <SubMenu title={<span className="submenu-title-wrapper">
                                    <Icon type="file-done" />订单管理</span>}>
                                    <Menu.Item key="order-list">
                                        <NavLink to="/main/orderlist">订单列表</NavLink>
                                    </Menu.Item>
                                    <Menu.Item key="order-form">
                                        <NavLink to="/main/orderInclude">导入订单</NavLink>                                      
                                    </Menu.Item>
                                    <Menu.Item key="order-express">
                                        <NavLink to="/main/expressList">物流设置</NavLink>                                      
                                    </Menu.Item>
                                </SubMenu> */}
                                <Menu.Item key="wo">
                                    <NavLink to="/main/wo/wolist">
                                        {/* <Icon type="file-done" /> */}
                                        &nbsp;&nbsp;工单
                                    </NavLink>                            
                                </Menu.Item>
                                {/* <SubMenu title={<span className="submenu-title-wrapper"><Icon type="file-done" />工单管理</span>}>
                                        <Menu.Item key="setting:1">
                                            <NavLink to="/main/workOrderList">我的工单</NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="setting:2">
                                            <NavLink to="/main/submitWorkOrder">提交工单</NavLink>                                      
                                        </Menu.Item>
                                        <Menu.Item key="setting:3">
                                            <NavLink to="/help/index" target="_blank">帮助中心</NavLink>                                      
                                        </Menu.Item>
                                </SubMenu> */}
                                <Menu.Item key="help">
                                    <NavLink to="/main/logistics_provider">
                                        {/* <Icon type="file-done" /> */}
                                        &nbsp;&nbsp;APP申请
                                    </NavLink>                            
                                </Menu.Item>
                                <Menu.Item key="cart" style={{float:'right'}}>
                                    
                                        <NavLink to="/main/cart"> 
                                            <Badge count={this.props.cartProductCount}>                                        
                                                <ShoppingCartOutlined />           
                                            </Badge>                                 
                                        </NavLink>   
                                                             
                                </Menu.Item>

                                <SubMenu title={<span className="submenu-title-wrapper">
                                    {/* <FontAwesomeIcon icon="user"/> */}
                                    &nbsp;&nbsp;{this.props.count}</span>} style={{float:'right'}}>
                                    {/* <MenuItemGroup title="Item 1"> */}
                                        <Menu.Item key="k-user-message">
                                            <NavLink to="/main/person">
                                                {/* <FontAwesomeIcon icon="users-cog"/> */}
                                                &nbsp;账户信息
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="k-user-set">
                                            <NavLink to="/main/message">
                                                {/* <FontAwesomeIcon icon="bell"/> */}
                                                &nbsp;
                                                <Badge count={this.props.messageCount}>
                                                    消息
                                                </Badge>
                                            </NavLink>                                     
                                        </Menu.Item>
                                        <Menu.Item key="k-user-edit-list">
                                            <NavLink to="/main/collectioneditlist">
                                                {/* <Icon type="edit"/> */}
                                                定制列表
                                            </NavLink>                                     
                                        </Menu.Item>
                                        <Menu.Item key="k-user-login-out" onClick={this.loginOut}>
                                            {/* <FontAwesomeIcon icon="sign-in-alt"/> */}
                                            &nbsp;退出
                                        </Menu.Item>
                                    {/* </MenuItemGroup> */}
                                </SubMenu>
                                {/* <Menu.Item key="y-count" style={{float:'right'}}>
                                    <FontAwesomeIcon icon="user"/> 账户
                                </Menu.Item> */}
                                {/* <Menu.Item key="alipay">
                                    <a href="https://ant.design" target="_blank" rel="noopener noreferrer">Navigation Four - Link</a>
                                </Menu.Item> */}
                                {/* <div>
                                    <Button type="primary">Primary</Button>
                                </div> */}

                            </Menu>
                        </Col>
                        {/* <Col span={2}>
                            <div className="y-flex" style={{height:'48px',borderBottom:'1px solid #E8E8E8'}}>
                                <span>账户名称</span>
                            </div>
                        </Col> */}
                    </Row>
                </div>
                <div>

                </div>
            </header>
        )
    }
}
export default Header