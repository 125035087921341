import axios from 'axios';
import { config } from './conifg/config'
import { message } from 'antd';
// import store from './store';
// import { updateConfig } from './redux/actions/config_action';

/**
 * 对象拼接成链接形式
 * @param params 
 */
function urlParamsStringify(params: any) {
    if (typeof params !== 'object') {
        return "";
    }
    let paramsStr = ""
    for (let item in params) {
        paramsStr += `${item}=${params[item]}&`
    }
    return paramsStr.substring(0, paramsStr.length - 2);
}
function getToken() {
    return ck.getCookie("token")
}
// /**
//  * 获取动态配置文件，并且更新到store
//  */
// function getConfigJson(){
//     return new Promise((resolve,reject)=>{
//         axios.get("./config.json")
//         .then((res:any)=>{
//             console.log(res);
//             let data = res.data;
//             data["config_update_timestamp"] = new Date().getTime();
//             store.dispatch(updateConfig(data));
//             console.log(store.getState());
//             resolve(data)
//         })
//         .catch((err:any)=>{
//             console.log(err);
//             reject(err)
//         })
//     })
// }
let http = {
    /**
     * get请求
     * @param url 请求地址
     * @param params 参数，该参数会被添加的地址上
     */
    get: function (url: string, params?: any) {
        //如果有请求参数，则添加到链接
        let paramsStr = ""
        if (params && typeof params === 'object') {
            paramsStr = "?" + urlParamsStringify(params);
        }
        return new Promise((resolve, reject) => {
            axios.get(`${config.serverUrl}${url}${paramsStr}`)
                .then((res: any) => {
                    if (res.status !== 200) {
                        reject({ errType: "网络请求", errContent: `status:${res.status}` })
                    } else {
                        resolve(res.data)
                    }
                })
                .catch((err: any) => {
                    reject({ errType: "网络请求", errContent: `status:${err.response}` })
                })
        })
    },
    /**
     * post请求
     * @param url 请求地址
     * @param params 请求数据
     */
    post: function (url: string, params: any) {
        // let token = ck.getCookie("token");
        let header = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
                'token-email': localStorage.getItem("token_email")
            }
        }
        return new Promise((resolve, reject) => {
            axios.post(`${config.serverUrl}${url}`, params, header)
                .then((res: any) => {
                    if (res.status !== 200) {
                        reject({ errType: "网络请求", message: `status:${res.status}` })
                    } else {
                        var resData = {
                            code:res.data.code,
                            data:res.data.data,
                            message:res.data.message
                        }
                        resolve(resData)
                    }
                })
                .catch((err: any) => {
                    console.log(err)
                    if(!err.response){
                        message.error("网络请求失败!")
                    }else{
                        if (err.response.status === 401) {
                            message.error('登录超时,请从新登录！');
                            // window.location.href = "/#/login";
                        } else if (err.response.status === 500) {
                            message.error('服务器错误！');
                        } else if (err.response.status === 403) {
                            message.error('服务器拒绝了您的请求！');
                        } else if (err.response.status === 404) {
                            message.error('该数据不存在！');
                        }
                    }
                    reject({ errType: "网络请求", message: `${err}` })
                })
        })
    }
}
function getFormatTime(timeStamp: number) {
    let date = new Date();
    if (timeStamp) {
        date.setTime(timeStamp * 1000);
    }
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours() > 9 ? date.getHours() : "0" + date.getHours()}:${date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()}:${date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds()}`;
}
class UserSet {
    data: any = {
        bindAlert: false,
        nextAuto: false,
    }
    setUserSetting(key: string, value: string) {

    }
}
/**
 * 获取时间
 * @param timeStamp 
 * @returns h:m:s
 */
function getTime(timeStamp?: number) {
    let date = new Date();
    if (timeStamp) {
        date.setTime(timeStamp * 1000);
    }
    return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
}
let tags: Array<any> = [];//公共标签
let personal: Array<any> = [];//私人标签
function getFlags() {
    return new Promise((resolve, reject) => {
        if (tags.length > 0) {
            resolve(tags);
            return;
        }
        http.post("/api/mail/flagList", {}).then((res: any) => {
            if (res.code != 0) {
                message.error(res.message);
                reject(res.message)
                return
            }
            tags = res.data;
            resolve(tags)
        }).catch(err => {
            // console.log(err)
            message.error("获取标签失败，失败原因：" + err.message);
            reject("获取标签失败，失败原因：" + err.message);
        })
    })
}
let flags = {
    publicFlags: [],
    personalFlags: [],
    getPublicFlags: function () {
        return new Promise((resolve, reject) => {
            if (this.publicFlags.length > 0) {
                resolve(this.publicFlags);
                return;
            }
            http.post("/api/mail/flagList", {}).then((res: any) => {
                if (res.code != 0) {
                    message.error(res.message);
                    reject(res.message)
                    return
                }
                this.publicFlags = res.data;
                resolve(this.publicFlags)
            }).catch(err => {
                // console.log(err)
                message.error("获取标签失败，失败原因：" + err.message);
                reject("获取标签失败，失败原因：" + err.message);
            })
        })
    },
    getPeronalFlags: function (update?: boolean) {
        console.log("getPeronalFlags")
        return new Promise((resolve, reject) => {
            if (this.personalFlags.length > 0 && !update) {
                resolve(tags);
                return;
            }
            http.post("/api/mail/personalFlag", {}).then((res: any) => {
                console.log("per flags")
                console.log(res)
                if (res.code != 0) {
                    message.error(res.message);
                    reject(res.message)
                    return
                }
                this.personalFlags = res.data;
                resolve(this.personalFlags)
            }).catch(err => {
                // console.log(err)
                message.error("获取标签失败，失败原因：" + err.message);
                reject("获取标签失败，失败原因：" + err.message);
            })
        })
    },
    getAllFlags: function () {
        let pro = [this.getPeronalFlags(), this.getPublicFlags()];
        return new Promise((resolve, reject) => {
            Promise.all(pro).then((res) => {
                resolve(this.publicFlags.concat(this.personalFlags))
            }).catch(err => {
                reject("获取标签失败！")
            })
        })
    }
}
// function getCurrencySign(currencyCode:string){
//     let sign = "";
//     switch(currencyCode){
//         case "USD" {
//             sign = "$";
//             break
//         }
//         case "AUD"{
//             sign = ""
//         }
//     }
// }
let ck = {
    setCookie: function (name: string, value: string) {
        var Days = 30;
        var exp = new Date();
        exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
        document.cookie = name + "=" + escape(value) + ";expires=" + exp.toUTCString();
    },
    //读取cookies
    getCookie: function (name: string) {
        let arr = document.cookie.split(";");
        let str = "";
        for (let i = 0; i < arr.length; i++) {
            let data = arr[i].split("=");
            if (data.length == 2) {
                if (data[0].replace(" ", "") === name) {
                    str = unescape(data[1]);
                    break;
                }
            }
        }
        return str;
        // var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
        // if (arr = document.cookie.match(reg))
        //     return unescape(arr[2]);
        // else
        //     return null;
    },
    clearCookie: function () {
        var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
        var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
        if (keys) {
            for (var i = keys.length; i--;)
                document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
        }
        // if (keys) {
        //     for (var i = keys.length; i--;) {
        //         document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString();//清除当前域名下的,例如：m.kevis.com
        //         document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString();//清除当前域名下的，例如 .m.kevis.com
        //         document.cookie = keys[i] + '=0;path=/;domain=kevis.com;expires=' + new Date(0).toUTCString();//清除一级域名下的或指定的，例如 .kevis.com
        //     }
        // }
    }
}
let emailTemplate = {
    resolveTemplate: function (str: string) {
        console.log(str[1])
        var reg = /(${)[a-zA-Z]{n}(})/i;
        var result = reg.exec(str);
        console.log(result)
    }
}
class AppUserConfig {
    data: any = {
        currentListStatus: "-1",//-1=>未处理，1=>待处理
    };
    getConfig(key?: string) {
        let config = JSON.parse(localStorage.getItem("app_user_config") || "");
        if (!key) {
            return config;
        } else if (config[key]) {
            return config[key]
        } else {
            return ""
        }
    };
    setConfig(key: string, value: any) {
        this.data[key] = value;
        localStorage.setItem("app_user_config", JSON.stringify(this.data));
    };
    init() {
        let currentData = localStorage.getItem("app_user_config");
        if (currentData) {
            this.data = {
                ...this.data,
                ...JSON.parse(currentData)
            }
        } else {
            localStorage.setItem("app_user_config", JSON.stringify(this.data));
        }
    }
}
var appUserConfig = new AppUserConfig();
appUserConfig.init();
//子账号,作为公共数据，避免重复请求
let childrenAccount = {
    childrenAccountList: [],
    getChildrenAccountList() {
        return new Promise((resolve, reject) => {
            if (this.childrenAccountList.length !== 0) {
                resolve(this.childrenAccountList)
            } else {
                http.post("/api/mail/childrenUserList", {}).then((res: any) => {
                    console.log(res)
                    if (res.code === 0) {
                        this.childrenAccountList = res.data;
                        resolve(this.childrenAccountList)
                    } else {
                        reject(res.message)
                    }
                }).catch(err => {
                    reject(err)
                })
            }
        })
    }
}
var getUUID = function () {
    var str = [];
    var Chars = '0123456789abcdefghijklmnopqrstuvwxyz';
    for (var i = 0; i < 36; i++) {
        str[i] = Chars.substr(Math.floor(Math.random() * 16), 1)
    }
    str[0] = str[8] = str[13] = str[18] = str[23] = '-';
    return str.join("")
}
let serviceList = [{
    title:"ALL",
    value:"",
    id:getUUID().split("-").join(""),
    children:new Array()
}]
/**
 * 获取服务商列表
 */
function getServiceList(){
    return new Promise((resovle,reject)=>{
        if(serviceList.length !== 1){
            resovle(serviceList)
        }
        http.post("/track/serviceData1",{}).then((res:any)=>{  
            if(res.code === 0){
                // serviceList = serviceList.concat(res.data.map((item:any)=>{return {
                //     title:item,
                //     value:item
                // }}))
                var keyArr = Object.getOwnPropertyNames(res.data);
                for(var i=0;i<keyArr.length;i++){
                    var serviceData = {
                        title:"",
                        value:"",
                        id:getUUID().split("-").join(""),
                        children:new Array()
                    }
                    var tempData = res.data[keyArr[i]];
                    serviceData.title = tempData.parent;
                    serviceData.value = tempData.parent;
                    var serviceKey = Object.getOwnPropertyNames(tempData);
                    for(var j=0;j<serviceKey.length;j++){
                        if(serviceKey[j] !== "parent"){
                            serviceData.children.push({
                                title:tempData[serviceKey[j]].children,
                                value:tempData[serviceKey[j]].children,
                                id:getUUID().split("-").join("")
                            })
                        }
                    }
                    serviceList.push(serviceData);
                }
                console.log(serviceList);
                resovle(serviceList);
            }else{
                message.error(res.message);
                reject(res.message)
            }
        }).catch(err=>{
            // console.log(err)
            reject(err.message)
        })
    })
}
/**
 * 设置cookie
 * @param name 名称
 * @param value 值
 * @param time 有效期,单位：分钟
 */
function setCookie(name:string,value:string,time?:number){
    var times = time ? time*60*1000 : 20*60*1000;
    var exp = new Date(); 
    exp.setTime(exp.getTime() + times); 
    document.cookie = name + "="+ escape (value) + ";expires=" + exp.toUTCString(); 
}
/**
 * 读取cookie
 * @param name 名称
 */
 function getCookie(name:string){
    var cookies = document.cookie;
    var list = cookies.split("; ");          // 解析出名/值对列表        
    for(var i = 0; i < list.length; i++) {
        var arr = list[i].split("=");          // 解析出名和值
        if(arr[0] === name)
        return decodeURIComponent(arr[1]);   // 对cookie值解码
    } 
    return "";
}
export { http, getTime, getFormatTime, getFlags, ck, emailTemplate, getToken, flags, appUserConfig, childrenAccount,getServiceList,setCookie,getCookie }