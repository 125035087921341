import React,{ Component,Suspense } from 'react';
import {
    BrowserRouter as Router,
    Route,
    NavLink,
    Switch,
    HashRouter
} from 'react-router-dom';
const Home = React.lazy(()=>import('../pages/home'));
const LogisticsProvider = React.lazy(()=>import("../pages/logistics_provider"));
// const Image = React.lazy(()=>import('../pages/image_page'));
// const SWO = React.lazy(()=>import('../pages/submit_work_order'));
// const MWO = React.lazy(()=>import('../pages/my_work_order'));
// const WOD = React.lazy(()=>import('../pages/work_order_detail'));
// const orderIncludeView = React.lazy(()=>import('../pages/order_include'));
// const SYSMessage = React.lazy(()=>import('../pages/sys_message'));
// const personCenter = React.lazy(()=>import('../pages/person_center'));
// const order = React.lazy(()=>import('../pages/order'));
// const WO = React.lazy(()=>import('../pages/work_order'));
// const cart = React.lazy(()=>import('../pages/cart'));
// const baregoods = React.lazy(()=>import('../pages/bare_goods_list'));
// const productDetail = React.lazy(()=>import('../pages/product_detail'));
// const warehouse = React.lazy(()=>import('../pages/warehouse_res'));
// const messageDetail = React.lazy(()=>import('../pages/message_detail'));
// const editList = React.lazy(()=>import("../pages/edit_list"));
// const collectionEdit = React.lazy(()=>import("../pages/collection_edit_list"));
class BaseRouter extends Component {
    render() {
        return (
            <HashRouter>
                <Switch>
                    <Suspense fallback={<div className="y-flex" style={{width:'100vw',height:"80vh"}}>loading...</div>}>
                        <Route exact path="/main/" component={Home} />
                        <Route path="/main/home" component={Home} />
                        <Route path="/main/logistics_provider" component={LogisticsProvider} />
                        {/* <Route path="/main/image" component={Image} />
                        <Route path="/main/submitWorkOrder" component={SWO} />
                        <Route path="/main/workOrderList" component={MWO} /> */}
                        {/* id为工单号 */}
                        {/* <Route path="/main/workOrderDetail/:id" component={WOD}/>
                        <Route path="/main/message" component={SYSMessage}></Route>
                        <Route path="/main/order" component={order}></Route>
                        <Route path="/main/warehouse" component={warehouse}></Route>
                        <Route path="/main/person" component={personCenter}></Route>
                        <Route path="/main/wo" component={WO}></Route>
                        <Route path="/main/cart" component={cart}></Route>
                        <Route path="/main/baregoods" component={baregoods}></Route>
                        <Route path="/main/product_detail/:sku" component={productDetail}></Route>
                        <Route path="/main/message_detail/:id" component={messageDetail}></Route>
                        <Route path="/main/editlist/:sku" component={editList}></Route>
                        <Route path="/main/collectioneditlist" component={collectionEdit}></Route> */}
                    </Suspense>
                </Switch>
            </HashRouter>
        )
    }
}
export default BaseRouter;