import React from 'react';
import { Button, Form, Checkbox, Input, message } from 'antd';
import { http } from '../utils';
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
export default class LogisticsProvider extends React.Component {
    constructor(props:any){
        super(props);
        this.register = this.register.bind(this);
    }
    state = {
        userName:"",
        loading:false,
    }
    register(){
        this.setState({loading:true})
        http.post("/api/track/appApplication",{
            name:this.state.userName
        }).then((res:any)=>{
            console.log(res)
            if(res.code === 0){
                message.success(res.message);
                this.setState({loading:false,userName:""})
                return
            }else{
                message.error(res.message);
                this.setState({loading:false})
            }
        }).catch((err:any)=>{
            message.error(err.message)
            this.setState({loading:false})
        })
    }
    render() {
        return <div className="section-logistics">
            <ul className="l-register-input">
                <li>
                    <div className="input-title">
                        申请名称
                    </div>
                    <div className="input-content">
                        <Input value={this.state.userName} onChange={(event)=>{ this.setState({userName:event.target.value}) }}/>
                    </div>
                </li>
                {/* <li>
                    <div className="input-title">
                        联系人
                    </div>
                    <div className="input-content">
                        <Input />
                    </div>
                </li> */}
                <li>
                    <div className="input-title">
                    </div>
                    <div className="input-content">
                        <Button type="primary" onClick={this.register} loading={this.state.loading}>申请</Button>
                    </div>
                </li>
                {/* <li>
                    <div className="input-title">
                        联系电话
                    </div>
                    <div className="input-content">
                        <Input />
                    </div>
                </li>
                <li>
                    <div className="input-title">
                        企业名称
                    </div>
                    <div className="input-content">
                        <Input />
                    </div>
                </li> */}
            </ul>
        </div>
    }
}